body,
h1,
h2 {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

img {
  vertical-align: top;
}

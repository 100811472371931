@borderColor:#646cff;
@borderRadius: 8px;
@monitorClsPrefix: weapp-emonitor;

.@{monitorClsPrefix}-html {
  &-wrapper {
    padding: 10px 16px;
  }
}

.box {
  font-size: var(--font-size-10);
  color: @borderColor;
}

.ui-button {
  position: relative;
  outline: none;
  border: none;
  text-align: center;
  display: block;
  width: 100%;
  border-radius: 3px;
  cursor: pointer;
  font-size: var(--font-size-12);
  border: 1px solid var(--border-color);
  transition: var(--transition-all);
  line-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &-inline {
    display: inline-block;
    width: auto;
  }
  &-middle {
    padding: 6px 16px;
  }

  &-small {
    padding: 4px 6px;
    font-size: var(--font-size-12);
  }

  &-large {
    padding: 8px 16px;
    font-size: var(--font-size-14);
  }

  &-default {
    color: var(--main-fc);
    background-color: var(--base-white);
    &::before {
      display: block;
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      opacity: 0.2;
      transition: var(--transition-all);
    }
    &:hover {
      color: var(--primary);
      border-color: var(--primary);
      &::before {
        background-color: var(--primary);
      }
    }
  }

  &-success {
    color: var(--base-white);
    background-color: var(--success);
    &:hover {
      opacity: 0.85;
    }
  }

  &-warning {
    color: var(--base-white);
    background-color: var(--warning);
    &:hover {
      opacity: 0.85;
    }
  }

  &-info {
    color: var(--base-white);
    background-color: var(--info);
    &:hover {
      opacity: 0.85;
    }
  }

  &-danger {
    color: var(--base-white);
    background-color: var(--danger);
    &:hover {
      opacity: 0.85;
    }
  }

  &-primary {
    color: var(--base-white);
    background-color: var(--primary);
    &:hover {
      opacity: 0.85;
    }
  }

  &-default,
  &-success,
  &-warning,
  &-info,
  &-danger,
  &-primary {
    &.disabled {
      color: var(--base-white);
      background-color: var(--placeholder-fc);
      border-color: var(--transparent);
      cursor: not-allowed;
    }
  }
}

button {
  margin-left: 20px;
  margin-top: 20px;
}

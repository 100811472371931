@borderColor:#646cff;
@borderRadius: 8px;
/* --- 公共变量 --- */
:root {
  /* 基本单位 */
  --hd: 1px;

  /* --- Color 色彩 --- */

  /* Theme color 主题色 */
  --primary: #5d9cec;
  /* 主题色 */

  /* 聊天气泡背景*/
  --bubble-color: #dbefff;

  /* Auxiliary color 辅助色 */
  --success: #52c41a;
  /* 成功色 */
  --warning: #faad14;
  /* 普通警告色 */
  --danger: #ff4d4f;
  /* 强烈警告色 */
  --info: #68a5ff;
  /* 提示色 */
  --invalid: #bbbbbb;
  /* 禁用色 */

  /* Neutral color 中性色 */
  --base-white: #ffffff;
  /* 基础白色 */
  --base-black: #000000;
  /* 基础黑色 */
  --transparent: transparent;
  /* 透明色 */

  --main-fc: #111111;
  /* 主要文字色 */
  --regular-fc: #666666;
  /* 常规文字色 */
  --secondary-fc: #999999;
  /* 次要文字色 */
  --invalid-fc: var(--invalid);
  /* 失效文字色 */
  --placeholder-fc: #cccccc;
  /* 占位文字色 */
  --link-fc: var(--primary);
  /* 链接文字色 */

  --storke-color: var(--primary);
  /* 描边色 */
  --border-color: #e5e5e5;
  /* 边框色 */
  --diviling-line-color: #ebebeb;
  /* 间隔线色 */
  --box-shadow-color: rgba(0, 0, 0, 0.12);
  /* 投影色 */
  --border-color-active: var(--primary);
  /* 高亮显示边框色 */

  --m-bg-base: #f7f7f7;
  /* h5 背景颜色 */
  --bg-base: #f9f9f9;
  /* 用于背景、输入框、标签以及一些需要加背景颜色的地方 */
  --bg-weak: #f1f1f1;
  /* 比浅背景色 */
  --bg-tap: #ebeff5;
  /* 按下背景色 */
  --label-bc: #f5f5f5;
  /* 标签/背景色 */
  --label-bc-active: #e9f7ff;
  /* 标签/选中背景色 */
  --mask-bc: rgba(0, 0, 0, 0.4);
  /* 蒙层背景色 */
  --tip-bc: rgba(0, 0, 0, 0.4);
  /* 轻提示背景色 */
  --hover-bc: #ecf5ff;
  /* 鼠标划过背景色 */
  --modal-bc: var(--bg-base);
  /* 弹框背景色 */
  --modal-top-bc: var(--bg-base);
  /* 弹框顶部背景色 */
  --invalid-bc: var(--invalid);
  /* 禁用背景色 */

  --collection-color: #f9c300;
  /* 选中收藏色 */

  /* --- Typegraphy 字体 --- */

  /* Font family 字体 */
  --regular-ff: Arial, PingFangSC-Light, Hiragino Sans GB, Microsoft YaHei,
    sans-serif;
  /*--regular-ff: PingFangSC-Regular, Microsoft YaHei, Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Arial, sans-serif; */

  /* 常规体 */
  --medium-ff: PingFangSC-Medium, Microsoft YaHei, Helvetica Neue, Helvetica,
    PingFang SC, Hiragino Sans GB, Arial, sans-serif;
  /* 中黑体 */
  --bold-ff: PingFang-SC-Bold, Microsoft YaHei, Helvetica Neue, Helvetica,
    PingFang SC, Hiragino Sans GB, Arial, sans-serif;
  /* 粗体 */

  /* Font spacing 间距（都以8的倍数计算） */
  --spacing-sm: calc(var(--hd) * 8 * 1);
  /* 字体小间距 */
  --spacing-md: calc(var(--hd) * 8 * 2);
  /* 字体中间距 */
  --spacing-lg: calc(var(--hd) * 8 * 3);
  /* 字体大间距 */

  /* Font Size 字号 */
  --font-size-10: calc(10 * var(--hd));
  /* 10字号 */
  --font-size-12: calc(12 * var(--hd));
  /* 12字号 */
  --font-size-14: calc(14 * var(--hd));
  /* 14字号 */
  --font-size-15: calc(15 * var(--hd));
  /* 15字号 */
  --font-size-16: calc(16 * var(--hd));
  /* 16字号 */
  --font-size-17: calc(17 * var(--hd));
  /* 17字号 */
  --font-size-18: calc(18 * var(--hd));
  /* 18字号 */

  --font-size-sm: var(--font-size-12);
  /* 小号字体 */
  --font-size-md: var(--font-size-14);
  /* 中号字体 */
  --font-size-lg: var(--font-size-16);
  /* 大号字体 */

  --font-size-icontext: var(--font-size-10);
  /* 图标字号 */
  --font-size-caption-sm: var(--font-size-12);
  /* 小标题字号 */
  --font-size-base: var(--font-size-14);
  /* 正文字号 */
  --font-size-subhead: var(--font-size-15);
  /* 自标题字号 */
  --font-size-caption: var(--font-size-16);
  /* 标题字号 */
  --font-size-heading: var(--font-size-17);
  /* 头部字号 */

  /* Line height 行高 */
  --line-height-base: calc(40 * var(--hd));
  /* 列表字体14px的时候 */
  --line-height-sm: calc(35 * var(--hd));
  /* 列表字体12px的时候 */

  /* Font weight 字体粗细 */
  --font-weight-base: 400;
  --font-weight-thin: 200;
  --font-weight-bold: 600;

  /* --- Spacing 间距 --- */

  /* Horizontal spacing 水平间距 */
  --h-spacing-xs: calc(4 * var(--hd));
  --h-spacing-sm: calc(6 * var(--hd));
  --h-spacing-md: calc(8 * var(--hd));
  --h-spacing-lg: calc(16 * var(--hd));
  --h-spacing-xl: calc(24 * var(--hd));

  /* Vertical spacing 垂直间距 */
  --v-spacing-xs: calc(4 * var(--hd));
  --v-spacing-sm: calc(6 * var(--hd));
  --v-spacing-md: calc(8 * var(--hd));
  --v-spacing-lg: calc(16 * var(--hd));
  --v-spacing-xl: calc(24 * var(--hd));

  /* --- Border 边框 --- */

  /* Border 边框 */
  --border-width: calc(1 * var(--hd));
  /* 边框宽度 */
  --border-width-lg: calc(2 * var(--hd));
  /* 边框宽度 加粗 */
  --border-solid: var(--border-width) solid var(--border-color);
  /* 实线边框 */
  --border-dotted: var(--border-width-lg) dotted var(--border-color);
  /* 虚线边框 */

  /* Diviling line 分割线 */
  --diviling-line-width: calc(1 * var(--hd));
  /* 分割线宽度 */

  /* Border radius 圆角 */
  --border-radius-none: 0;
  /* 无圆角 */
  --border-radius-xs: calc(3 * var(--hd));
  /* 小圆角 */
  --border-radius-sm: calc(6 * var(--hd));
  /* 中圆角 */
  --border-radius-md: calc(8 * var(--hd));
  /* 大圆角 */
  --border-radius-lg: calc(15 * var(--hd));
  /* 圆形圆角 */

  /* Box shadow 投影 */
  --box-shadow-x: 0;
  /* 水平投影 */
  --box-shadow-y: calc(3 * var(--hd));
  /* 垂直投影 */
  --box-shadow-blur: calc(12 * var(--hd));
  /* 投影扩散 */
  --box-shadow-spread: calc(1 * var(--hd));
  /* 投影描边 */
  --box-shadow: var(--box-shadow-x) var(--box-shadow-y) var(--box-shadow-blur)
    var(--box-shadow-color);
  /* 无描边投影 */
  --box-shadow-storke: var(--box-shadow-x) var(--box-shadow-y)
    var(--box-shadow-blur) var(--box-shadow-spread) var(--box-shadow-color);
  /* 有描边投影 */

  /* z-index */
  --progress-zindex: 2000;
  --popover-zindex: 1999;
  --toast-zindex: 1999;
  --action-sheet-zindex: 1000; /* actonsheet 会放到 popup / modal 中*/
  --picker-zindex: 1000;
  --popup-zindex: 999;
  --dialog-draw-zindex: 900; /* modal.alert 应该最大，其他应该较小*/
  --modal-zindex: 999; /* modal.alert 应该最大，其他应该较小*/
  --tabs-pagination-zindex: 999;
  --dialog-route-layout-zindex: 998; /* 移动端路由层*/
  --dialog-confirm-zindex: 2999; /* 移动端路由层*/
  --map-zindex: 9; /* 地图的层级*/
  --map-wrap-zindex: 10; /* 地图内搜索框 switch框的层级*/

  /* transition 动画 */
  --transition-du: 0.3s;
  --transition-tf: cubic-bezier(0.645, 0.045, 0.355, 1);
  --transition-all: all var(--transition-du) var(--transition-tf);
  --transition-color: color var(--transition-du) var(--transition-tf);
  --transition-bc: background-color var(--transition-du) var(--transition-tf);
  --transition-w: width var(--transition-du) var(--transition-tf);
  --transition-h: height var(--transition-du) var(--transition-tf);

  --c-main: #351c75;
  --c-secondary: #e69138;
  --c-dark: #333;
  --c-background: #fce5cd;
  --c-background-dark: #f7d7b5;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html,
body {
  width: 100%;
  height: 100%;
}

#root {
  height: 100%;
  width: 100%;
}

.ant-layout {
  height: 100%;
}

@import '../component/button/style/index.less';
@import '@/assets/css/reset.css';
